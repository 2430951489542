import {createPopper} from './createPopper.js'
import {MC} from '../MC.js'

var CONTENT_CLASS = "tippy-content";
var ARROW_CLASS = "tippy-arrow";
var TOUCH_OPTIONS = {passive: true, capture: true}

function getValueAtIndexOrReturn(value, index, defaultValue) {
  if (Array.isArray(value)) {
    var v = value[index];
    return v == null ? Array.isArray(defaultValue) ? defaultValue[index] : defaultValue : v;
  }

  return value;
}
function debounce(fn, ms) {
  // Avoid wrapping in `setTimeout` if ms is 0 anyway
  if (ms === 0) {
    return fn;
  }

  var timeout;
  return function (arg) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      fn(arg);
    }, ms);
  };
}
function arrayFrom(value) {
  return [].slice.call(value);
}

function div() {
  return document.createElement('div');
}
function isReferenceElement(value) {
  return !!(value && value._tippy && value._tippy.reference === value);
}
function getArrayOfElements(value) {
  if (value instanceof NodeList) {
    return [].slice.call(value)
  } else {
    return [value]
  }  
}
function setTransitionDuration(els, value) {
  els.forEach(function (el) {
    if (el) {
      el.style.transitionDuration = value + "ms";
    }
  });
}
function setVisibilityState(els, state) {
  els.forEach(function (el) {
    if (el) {
      el.setAttribute('data-state', state);
    }
  });
}
function isCursorOutsideInteractiveBorder(popperTreeData, event) {
  var clientX = event.clientX,
      clientY = event.clientY;
  return popperTreeData.every(function (_ref) {
    var popperRect = _ref.popperRect,
        popperState = _ref.popperState,
        props = _ref.props;
    var interactiveBorder = props.interactiveBorder;
    var basePlacement = popperState.placement.split('-')[0]
    var offsetData = popperState.modifiersData.offset;

    if (!offsetData) {
      return true;
    }

    var topDistance = basePlacement === 'bottom' ? offsetData.top.y : 0;
    var bottomDistance = basePlacement === 'top' ? offsetData.bottom.y : 0;
    var leftDistance = basePlacement === 'right' ? offsetData.left.x : 0;
    var rightDistance = basePlacement === 'left' ? offsetData.right.x : 0;
    var exceedsTop = popperRect.top - clientY + topDistance > interactiveBorder;
    var exceedsBottom = clientY - popperRect.bottom - bottomDistance > interactiveBorder;
    var exceedsLeft = popperRect.left - clientX + leftDistance > interactiveBorder;
    var exceedsRight = clientX - popperRect.right - rightDistance > interactiveBorder;
    return exceedsTop || exceedsBottom || exceedsLeft || exceedsRight;
  });
}
function updateTransitionEndListener(box, action, listener) {
  var method = action + "EventListener"; // some browsers apparently support `transition` (unprefixed) but only fire
  // `webkitTransitionEnd`...

  ['transitionend', 'webkitTransitionEnd'].forEach(function (event) {
    box[method](event, listener);
  });
}
/**
 * Compared to xxx.contains, this function works for dom structures with shadow
 * dom
 */

function actualContains(parent, child) {
  var target = child;

  while (target) {
    var _target$getRootNode;

    if (parent.contains(target)) {
      return true;
    }

    target = target.getRootNode == null ? void 0 : (_target$getRootNode = target.getRootNode()) == null ? void 0 : _target$getRootNode.host;
  }

  return false;
}

var currentInput = {
  isTouch: false
};
var lastMouseMoveTime = 0;
/**
 * When a `touchstart` event is fired, it's assumed the user is using touch
 * input. We'll bind a `mousemove` event listener to listen for mouse input in
 * the future. This way, the `isTouch` property is fully dynamic and will handle
 * hybrid devices that use a mix of touch + mouse input.
 */

function onDocumentTouchStart() {
  if (currentInput.isTouch) {
    return;
  }

  currentInput.isTouch = true;

  if (window.performance) {
    document.addEventListener('mousemove', onDocumentMouseMove);
  }
}
/**
 * When two `mousemove` event are fired consecutively within 20ms, it's assumed
 * the user is using mouse input again. `mousemove` can fire on touch devices as
 * well, but very rarely that quickly.
 */

function onDocumentMouseMove() {
  var now = performance.now();

  if (now - lastMouseMoveTime < 20) {
    currentInput.isTouch = false;
    document.removeEventListener('mousemove', onDocumentMouseMove);
  }

  lastMouseMoveTime = now;
}
/**
 * When an element is in focus and has a tippy, leaving the tab/window and
 * returning causes it to show again. For mouse users this is unexpected, but
 * for keyboard use it makes sense.
 * TODO: find a better technique to solve this problem
 */

function onWindowBlur() {
  var activeElement = document.activeElement;

  if (isReferenceElement(activeElement)) {
    var instance = activeElement._tippy;

    if (activeElement.blur && !instance.state.isVisible) {
      activeElement.blur();
    }
  }
}
function bindGlobalEventListeners() {
  document.addEventListener('touchstart', onDocumentTouchStart, TOUCH_OPTIONS);
  window.addEventListener('blur', onWindowBlur);
}

var renderProps = {
  allowHTML: false,
  animation: 'scale',
  content: '',
  maxWidth: 350,
  zIndex: 9999
};
var defaultProps = Object.assign({
  delay: 400,
  duration: [300, 250],
  hideOnClick: true,
  interactive: false,
  interactiveBorder: 2,
  interactiveDebounce: 0,
  placement: 'top',
  popperOptions: {},
  render: null,
  touch: true,
  trigger: 'mouseenter focus'
}, renderProps);
function evaluateProps(reference, props) {
  let content = (reference.getAttribute("data-tt-content") || '').trim()
  if (content) {
    props.content = content
  }
  return props
}

function setContent(content, props) {
  if (props.allowHTML) {
    content['innerHTML'] = props.content
  } else {
    content.textContent = props.content
  }
}
function getChildren(popper) {
  var box = popper.firstElementChild;
  var boxChildren = arrayFrom(box.children);
  return {
    box: box,
    content: boxChildren.find(function (node) {
      return node.classList.contains(CONTENT_CLASS);
    }),
    arrow: boxChildren.find(function (node) {
      return node.classList.contains(ARROW_CLASS);
    })
  };
}
function render(instance) {
  var popper = div();
  var box = div();
  box.className = 'tippy-box'
  box.setAttribute('data-state', 'hidden');
  box.setAttribute('tabindex', '-1');
  var content = div();
  content.className = CONTENT_CLASS;
  content.setAttribute('data-state', 'hidden');
  setContent(content, instance.props);
  popper.appendChild(box);
  box.appendChild(content);
  onUpdate(instance.props, instance.props);

  function onUpdate(prevProps, nextProps) {
    var _getChildren = getChildren(popper),
        box = _getChildren.box,
        content = _getChildren.content,
        arrow = _getChildren.arrow;

    if (typeof nextProps.animation === 'string') {
      box.setAttribute('data-animation', nextProps.animation);
    }

    box.style.maxWidth = typeof nextProps.maxWidth === 'number' ? nextProps.maxWidth + "px" : nextProps.maxWidth;

    if (prevProps.content !== nextProps.content || prevProps.allowHTML !== nextProps.allowHTML) {
      setContent(content, instance.props);
    }

    if (!arrow) {
      let arrow = document.createElement('div')
      arrow.className = ARROW_CLASS
      box.appendChild(arrow)
    }
  }

  return {
    popper: popper,
    onUpdate: onUpdate
  };
} // Runtime check to identify if the render function is the default one; this
// way we can apply default CSS transitions logic and it can be tree-shaken away

render.$$tippy = true;

var idCounter = 1;

var mountedInstances = [];
function createTippy(reference, passedProps) {
  var props = evaluateProps(reference, Object.assign({}, defaultProps, passedProps)); 
  // ===========================================================================
  // 🔒 Private members
  // ===========================================================================

  var showTimeout;
  var hideTimeout;
  var scheduleHideAnimationFrame;
  var isVisibleFromClick = false;
  var didHideDueToDocumentMouseDown = false;
  var didTouchMove = false;
  var ignoreOnFirstUpdate = false;
  var lastTriggerEvent;
  var currentTransitionEndListener;
  var onFirstUpdate;
  var listeners = [];
  var debouncedOnMouseMove = debounce(onMouseMove, props.interactiveDebounce);
  var currentTarget; // ===========================================================================
  // 🔑 Public members
  // ===========================================================================

  var id = idCounter++;
  var popperInstance = null;
  var state = {
    // Is the tippy currently showing and not transitioning out?
    isVisible: false,
    // Has the instance been destroyed?
    isDestroyed: false,
    // Is the tippy currently mounted to the DOM?
    isMounted: false,
    // Has the tippy finished transitioning in?
    isShown: false
  };
  var instance = {
    // properties
    id: id,
    reference: reference,
    popper: div(),
    popperInstance: popperInstance,
    props: props,
    state: state,
    // methods
    clearDelayTimeouts: clearDelayTimeouts,
    setContent: setContent,
    show: show,
    hide: hide,
    hideWithInteractivity: hideWithInteractivity,
    unmount: unmount,
    destroy: destroy
  }; // TODO: Investigate why this early return causes a TDZ error in the tests —
  // it doesn't seem to happen in the browser

  var _props$render = props.render(instance),
      popper = _props$render.popper;

  popper.setAttribute('data-tt-root', '');
  popper.id = "tippy-" + instance.id;
  instance.popper = popper;
  reference._tippy = instance;
  popper._tippy = instance;
  addListeners();
  handleStyles();

  popper.addEventListener('mouseenter', function () {
    if (instance.props.interactive && instance.state.isVisible) {
      instance.clearDelayTimeouts();
    }
  });
  popper.addEventListener('mouseleave', function () {
    if (instance.props.interactive && instance.props.trigger.indexOf('mouseenter') >= 0) {
      document.addEventListener('mousemove', debouncedOnMouseMove);
    }
  });
  return instance; // ===========================================================================
  // 🔒 Private methods
  // ===========================================================================

  function getCurrentTarget() {
    return currentTarget || reference;
  }

  function getDefaultTemplateChildren() {
    return getChildren(popper);
  }

  function getDelay(isShow) {
    // For touch or keyboard input, force `0` delay for UX reasons
    // Also if the instance is mounted but not visible (transitioning out),
    // ignore delay
    if (instance.state.isMounted && !instance.state.isVisible || currentInput.isTouch || lastTriggerEvent && lastTriggerEvent.type === 'focus') {
      return 0;
    }

    return getValueAtIndexOrReturn(instance.props.delay, isShow ? 0 : 1, defaultProps.delay);
  }

  function handleStyles(fromHide) {
    if (fromHide === void 0) {
      fromHide = false;
    }

    popper.style.pointerEvents = instance.props.interactive && !fromHide ? '' : 'none';
    popper.style.zIndex = "" + instance.props.zIndex;
  }

  function cleanupInteractiveMouseListeners() {
    document.removeEventListener('mousemove', debouncedOnMouseMove);
  }

  function onDocumentPress(event) {
    // Moved finger to scroll instead of an intentional tap outside
    if (currentInput.isTouch) {
      if (didTouchMove || event.type === 'mousedown') {
        return;
      }
    }

    var actualTarget = event.composedPath && event.composedPath()[0] || event.target; // Clicked on interactive popper

    if (instance.props.interactive && actualContains(popper, actualTarget)) {
      return;
    } // Clicked on the event listeners target


    if (MC.asArray(reference).some(function (el) {
      return actualContains(el, actualTarget);
    })) {
      if (currentInput.isTouch) {
        return;
      }

      if (instance.state.isVisible && instance.props.trigger.indexOf('click') >= 0) {
        return;
      }
    }

    if (instance.props.hideOnClick === true) {
      instance.clearDelayTimeouts();
      instance.hide(); // `mousedown` event is fired right before `focus` if pressing the
      // currentTarget. This lets a tippy with `focus` trigger know that it
      // should not show

      didHideDueToDocumentMouseDown = true;
      setTimeout(function () {
        didHideDueToDocumentMouseDown = false;
      }); // The listener gets added in `scheduleShow()`, but this may be hiding it
      // before it shows, and hide()'s early bail-out behavior can prevent it
      // from being cleaned up

      if (!instance.state.isMounted) {
        removeDocumentPress();
      }
    }
  }

  function onTouchMove() {
    didTouchMove = true;
  }

  function onTouchStart() {
    didTouchMove = false;
  }

  function addDocumentPress() {
    document.addEventListener('mousedown', onDocumentPress, true);
    document.addEventListener('touchend', onDocumentPress, TOUCH_OPTIONS);
    document.addEventListener('touchstart', onTouchStart, TOUCH_OPTIONS);
    document.addEventListener('touchmove', onTouchMove, TOUCH_OPTIONS);
  }

  function removeDocumentPress() {
    document.removeEventListener('mousedown', onDocumentPress, true);
    document.removeEventListener('touchend', onDocumentPress, TOUCH_OPTIONS);
    document.removeEventListener('touchstart', onTouchStart, TOUCH_OPTIONS);
    document.removeEventListener('touchmove', onTouchMove, TOUCH_OPTIONS);
  }

  function onTransitionedOut(duration, callback) {
    onTransitionEnd(duration, function () {
      if (!instance.state.isVisible && popper.parentNode && popper.parentNode.contains(popper)) {
        callback();
      }
    });
  }

  function onTransitionedIn(duration, callback) {
    onTransitionEnd(duration, callback);
  }

  function onTransitionEnd(duration, callback) {
    var box = getDefaultTemplateChildren().box;

    function listener(event) {
      if (event.target === box) {
        updateTransitionEndListener(box, 'remove', listener);
        callback();
      }
    } // Make callback synchronous if duration is 0
    // `transitionend` won't fire otherwise


    if (duration === 0) {
      return callback();
    }

    updateTransitionEndListener(box, 'remove', currentTransitionEndListener);
    updateTransitionEndListener(box, 'add', listener);
    currentTransitionEndListener = listener;
  }

  function on(eventType, handler) {
    MC.asArray(reference).forEach(function (node) {
      node.addEventListener(eventType, handler)
      listeners.push({node: node, eventType: eventType, handler: handler})
    })
  }

  function addListeners() {
    instance.props.trigger.split(/\s+/).filter(Boolean).forEach(function (eventType) {
      on(eventType, onTrigger);
      switch (eventType) {
        case 'mouseenter': on('mouseleave', onMouseLeave);  break;
        case 'focus': on('blur', onBlurOrFocusOut); break;
        case 'focusin': on('focusout', onBlurOrFocusOut); break;
      }
    })
  }

  function removeListeners() {
    listeners.forEach(function (r) {
      r.node.removeEventListener(r.eventType, r.handler)
    })
    listeners = []
  }

  function onTrigger(event) {
    var shouldScheduleClickHide = false;
    if (isEventListenerStopped(event) || didHideDueToDocumentMouseDown) {
      return
    }
    var wasFocused = lastTriggerEvent?.type === 'focus'
    lastTriggerEvent = event
    currentTarget = event.currentTarget
    if (event.type === 'click' && (instance.props.trigger.indexOf('mouseenter') < 0 || isVisibleFromClick) && instance.props.hideOnClick !== false && instance.state.isVisible) {
      shouldScheduleClickHide = true
    } else {
      scheduleShow()
    }
    if (event.type === 'click') {
      isVisibleFromClick = !shouldScheduleClickHide
    }
    if (shouldScheduleClickHide && !wasFocused) {
      scheduleHide(event)
    }
  }

  function onMouseMove(event) {
    var target = event.target
    var isCursorOverReferenceOrPopper = getCurrentTarget().contains(target) || popper.contains(target)
    if (event.type === 'mousemove' && isCursorOverReferenceOrPopper) {
      return
    }
    var popperTreeData = getNestedPopperTree().concat(popper).map(function (popper) {
      var state = popper?._tippy?.popperInstance?.state
      return state ? {popperRect: popper.getBoundingClientRect(),popperState: state, props: props} : null
    }).filter(Boolean)
    if (isCursorOutsideInteractiveBorder(popperTreeData, event)) {
      cleanupInteractiveMouseListeners()
      scheduleHide(event)
    }
  }

  function onMouseLeave(event) {
    var shouldBail = isEventListenerStopped(event) || instance.props.trigger.indexOf('click') >= 0 && isVisibleFromClick;

    if (shouldBail) {
      return;
    }

    if (instance.props.interactive) {
      instance.hideWithInteractivity(event);
      return;
    }

    scheduleHide(event);
  }

  function onBlurOrFocusOut(event) {
    if (instance.props.trigger.indexOf('focusin') < 0 && event.target !== getCurrentTarget()) {
      return;
    } // If focus was moved to within the popper


    if (instance.props.interactive && event.relatedTarget && popper.contains(event.relatedTarget)) {
      return;
    }

    scheduleHide(event);
  }

  function isEventListenerStopped(event) {
    return currentInput.isTouch ? event.type.indexOf('touch') >= 0 : false
  }

  function createPopperInstance() {
    destroyPopperInstance()
    var getReferenceClientRect = instance.props.getReferenceClientRect
    var computedReference = getReferenceClientRect ? {getBoundingClientRect: getReferenceClientRect, contextElement: getReferenceClientRect.contextElement || getCurrentTarget()} : reference;
    var tippyModifier = {
      name: '$$tippy',
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: function fn(opts) {
        var box = getDefaultTemplateChildren().box
        box.setAttribute('data-placement', opts.state.placement);
        ['reference-hidden', 'escaped'].forEach(function (attr) {
          if (opts.state.attributes.popper["data-popper-" + attr]) {
            box.setAttribute("data-" + attr, '')
          } else {
            box.removeAttribute("data-" + attr)
          }
        });
        opts.state.attributes.popper = {}
      }
    }
    instance.popperInstance = createPopper(computedReference, popper, Object.assign({}, instance.props.popperOptions, {
      placement: instance.props.placement,
      onFirstUpdate: onFirstUpdate,
      arrow: getChildren(popper).arrow,
      modifier: tippyModifier
    }))
  }

  function destroyPopperInstance() {
    if (instance.popperInstance) {
      instance.popperInstance.destroy();
      instance.popperInstance = null;
    }
  }

  function mount() {
    let parentNode;
    let node = getCurrentTarget()
    if (instance.props.interactive) {
      parentNode = node.parentNode
    } else {
      parentNode = document.body
    }
    if (!parentNode.contains(popper)) {
      parentNode.appendChild(popper)
    }
    instance.state.isMounted = true
    createPopperInstance()
  }

  function getNestedPopperTree() {
    return arrayFrom(popper.querySelectorAll('[data-tt-root]'));
  }

  function scheduleShow() {
    instance.clearDelayTimeouts();
    addDocumentPress();
    var delay = getDelay(true);
    if (delay) {
      showTimeout = setTimeout(function () {
        instance.show();
      }, delay);
    } else {
      instance.show();
    }
  }

  function scheduleHide(event) {
    instance.clearDelayTimeouts();

    if (!instance.state.isVisible) {
      removeDocumentPress();
      return;
    } // For interactive tippies, scheduleHide is added to a document.body handler
    // from onMouseLeave so must intercept scheduled hides from mousemove/leave
    // events when trigger contains mouseenter and click, and the tip is
    // currently shown as a result of a click.


    if (instance.props.trigger.indexOf('mouseenter') >= 0 && instance.props.trigger.indexOf('click') >= 0 && ['mouseleave', 'mousemove'].indexOf(event.type) >= 0 && isVisibleFromClick) {
      return;
    }

    var delay = getDelay(false);

    if (delay) {
      hideTimeout = setTimeout(function () {
        if (instance.state.isVisible) {
          instance.hide();
        }
      }, delay);
    } else {
      // Fixes a `transitionend` problem when it fires 1 frame too
      // late sometimes, we don't want hide() to be called.
      scheduleHideAnimationFrame = requestAnimationFrame(function () {
        instance.hide();
      });
    }
  } // ===========================================================================
  // 🔑 Public methods
  // ===========================================================================

  function clearDelayTimeouts() {
    clearTimeout(showTimeout);
    clearTimeout(hideTimeout);
    cancelAnimationFrame(scheduleHideAnimationFrame);
  }

  function show() {
    var isAlreadyVisible = instance.state.isVisible;
    var isDestroyed = instance.state.isDestroyed;
    var duration = getValueAtIndexOrReturn(instance.props.duration, 0, defaultProps.duration);
    if (isAlreadyVisible || isDestroyed) {
      return;
    } 
    // Normalize `disabled` behavior across browsers.
    // Firefox allows events on disabled elements, but Chrome doesn't.
    // Using a wrapper element (i.e. <span>) is recommended.
    if (getCurrentTarget().hasAttribute('disabled')) {
      return;
    }
    instance.state.isVisible = true;
    popper.style.visibility = 'visible';
    handleStyles();
    addDocumentPress();
    if (!instance.state.isMounted) {
      popper.style.transition = 'none';
    } // If flipping to the opposite side after hiding at least once, the
    // animation will use the wrong placement without resetting the duration

    var defaultTemplateCh = getDefaultTemplateChildren()
    setTransitionDuration([defaultTemplateCh.box, defaultTemplateCh.content], 0);


    onFirstUpdate = function onFirstUpdate() {
      if (!instance.state.isVisible || ignoreOnFirstUpdate) {
        return;
      }
      ignoreOnFirstUpdate = true; // reflow
      void popper.offsetHeight;
      if (instance.props.animation) {
        let defaultTemplateCh2 = getDefaultTemplateChildren()
        setTransitionDuration([defaultTemplateCh2.box, defaultTemplateCh2.content], duration);
        setVisibilityState([defaultTemplateCh2.box, defaultTemplateCh2.content], 'visible');
      }
      if (mountedInstances.indexOf(instance) === -1) {
        mountedInstances.push(instance)
      }
      // certain modifiers (e.g. `maxSize`) require a second update after the
      // popper has been positioned for the first time
      if (instance.popperInstance) {
        instance.popperInstance.forceUpdate()
      }
      if (instance.props.animation) {
        onTransitionedIn(duration, function () {
          instance.state.isShown = true
        })
      }
    }

    mount()
  }

  function hide() {
    var isAlreadyHidden = !instance.state.isVisible;
    var isDestroyed = instance.state.isDestroyed;
    var duration = getValueAtIndexOrReturn(instance.props.duration, 1, defaultProps.duration);
    if (isAlreadyHidden || isDestroyed) {
      return;
    }
    instance.state.isVisible = false;
    instance.state.isShown = false;
    ignoreOnFirstUpdate = false;
    isVisibleFromClick = false;
    popper.style.visibility = 'hidden';
    cleanupInteractiveMouseListeners();
    removeDocumentPress();
    handleStyles(true);
    let defaultTemplateCh = getDefaultTemplateChildren()
    if (instance.props.animation) {
      setTransitionDuration([defaultTemplateCh.box, defaultTemplateCh.content], duration);
      setVisibilityState([defaultTemplateCh.box, defaultTemplateCh.content], 'hidden');
    }
    if (instance.props.animation) {
      onTransitionedOut(duration, instance.unmount);
    } else {
      instance.unmount()
    }
  }

  function hideWithInteractivity(event) {
    document.addEventListener('mousemove', debouncedOnMouseMove);
    debouncedOnMouseMove(event);
  }

  function unmount() {
    if (instance.state.isVisible) {
      instance.hide()
    }
    if (!instance.state.isMounted) {
      return
    }
    destroyPopperInstance(); 
    // If a popper is not interactive, it will be appended outside the popper
    // tree by default. This seems mainly for interactive tippies, but we should
    // find a workaround if possible
    getNestedPopperTree().forEach(function (nestedPopper) {
      nestedPopper._tippy.unmount();
    });
    if (popper.parentNode) {
      popper.parentNode.removeChild(popper)
    }

    mountedInstances = mountedInstances.filter(function (i) {
      return i !== instance;
    })
    instance.state.isMounted = false
  }

  function destroy() {
    if (instance.state.isDestroyed) {
      return
    }
    instance.clearDelayTimeouts()
    instance.unmount()
    removeListeners()
    delete reference._tippy
    instance.state.isDestroyed = true
  }
}

function tippy(targets, opts) {
  if (!opts) {
    opts = {}
  }
  opts = Object.assign({}, opts)
  bindGlobalEventListeners()
  let elements = getArrayOfElements(targets)
  let instances = elements.map(function (reference) {
    return createTippy(reference, opts)
  })
  return targets instanceof Element ? instances[0] : instances
}

tippy.defaultProps = defaultProps;
tippy.currentInput = currentInput;
defaultProps.render = render

export default tippy