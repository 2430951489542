import React from "react"

import {MC} from './MC.js'
import {Value} from "./Value.js"
import tt from "./tooltip/tooltip.js"

class Menu extends React.Component {

  state = {}

  constructor(props) {
    super(props)
    this.onclick = this.onclick.bind(this)
    this.handleRootMouseEnter = this.handleRootMouseEnter.bind(this)
    this.handleRootMouseLeave = this.handleRootMouseLeave.bind(this)
    this.menuRef = React.createRef()
    this.tolltipInstances = []
  }

  componentDidMount() {
    if (!MC.isModelerActive(this.props.data)) {
      if (this.menuRef.current) {
        let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
        this.tolltipInstances = tt(this.menuRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
      }
      document.addEventListener('MNC.CLICK', this.handleOutsideClick)
      document.addEventListener('click', this.handleOutsideClick)
    }
  }

  componentWillUnmount() {
    if (!MC.isModelerActive(this.props.data)) {
      document.removeEventListener('MNC.CLICK', this.handleOutsideClick)
      document.removeEventListener('click', this.handleOutsideClick)
    }
  }

  componentDidUpdate() {
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') || !MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')) {
      if (this.recalcDirection) {
        this.recalcDirection = false
        let dropMenu = this.menuRef.current ? this.menuRef.current.querySelector(".menu.transition.visible") : null
        if (dropMenu) {
          let off = MC.offset(dropMenu)
          let container = dropMenu.closest('.mnc.form')
          if (off.left + dropMenu.offsetWidth > container.offsetWidth) {
            this.setState({'right': true})
          }
        }
      }
    }
    if (this.toggled && this.menuRef.current) {
      this.toggled = false
      let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
      for (let i of this.tolltipInstances) {
        i.destroy()
      }
      this.tolltipInstances = tt(this.menuRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
    }  
  }  

  recalculateActiveKey(activeKey) {
    if (!MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') && MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')) {
      let items = MC.getFieldParamValue(this.props.data.param, 'items')
      if (Array.isArray(items) && activeKey) {
        for (let item of items) {
          let activeItem = false
          if (Array.isArray(item.items)) {
            activeItem = item.items.find((subitem) => {
              let activeSubItem = false
              if (Array.isArray(subitem.items)) {
                activeSubItem = subitem.items.find((ssubitem) => {
                  return ssubitem.key && ssubitem.key == activeKey
                })
              }
              if (subitem.key && subitem.key == activeKey || activeSubItem) {
                subitem.active = true
                subitem.opened = true
                return true
              }
              return false
            })
          }
          if (item.key && item.key == activeKey || activeItem) {
            item.active = true
            item.opened = true
          }
        }
      }
    }
    this.props.data.activeKeyWasRecalculated = true
    this.activeKey = activeKey
  }

  handleOutsideClick = (e) => {
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') || !MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')) {
      if (!this.menuRef.current || this.menuRef.current.contains(e.detail.target)) {
        return
      }
      this.closeDropDown(MC.getFieldParamValue(this.props.data.param, 'items'))
    }
  }

  getItemByIndex(index) {
    index = index.split("-")
    let item = MC.getFieldParamValue(this.props.data.param, 'items')[index.shift()]
    while (index.length > 0) {
      item = item.items[index.shift()]
    }
    return item
  }

  closeDropDown(items) {
    if (Array.isArray(items) && items.length > 0) {
      for (let item of items) {
        if (item.opened) {
          item.opened = false
          this.toggled = true
        }
        this.closeDropDown(item.items) 
      }  
    }
    this.forceUpdate()
  }

  onclick(e) {
    let item = this.getItemByIndex(e.currentTarget.dataset.i)
    if (MC.eventHasKey(e) && !MC.isNull(item.url)) {
      return
    }
    let field = this.props.data
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url' || item.enabled == false) {
      e.preventDefault()
    }
    e.stopPropagation()
    if (item.enabled == false) {
      return
    }
    MC.putFieldParamValue(this.props.data.param, '@focusedKey', item.key)
    if (Array.isArray(item['items']) && item['items'].length > 0 && (MC.isNull(item.url) || !MC.getFieldParamBooleanValue(this.props.data.param, '@vertical') || MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') )) {
      if (!item.opened && (MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') || !MC.getFieldParamBooleanValue(this.props.data.param, '@vertical'))) {
        let ind = e.currentTarget.dataset.i
        let parent = ind.includes('-') ? this.getItemByIndex(ind.substring(0, ind.lastIndexOf('-'))) : this.props.data.param
        this.closeDropDown(parent.items)
      }
      item.opened = !item.opened
      this.toggled = true
      if (item.opened) {
        this.recalcDirection = true
      }
      this.setState({'right': false})
      MC.handleEvent(field, 'change')
    } else {
      if (MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu') || !MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')) {
        this.closeDropDown(MC.getFieldParamValue(this.props.data.param, 'items'))
      }
      if (behavior == 'url') {
        if (!MC.isNull(item.url)) {
          let target = MC.getFieldParamValue(field.param, '@target')
          if (['blank', 'parent', 'top'].indexOf(target) > -1) {
            return
          }
          this.props.data.flow.reactFlow().routeTo(e, item.url)
          return
        }
      } else {
        MC.putFieldParamValue(this.props.data.param, '@activeKey', item.key)
        MC.handleEvent(field, 'change')
        this.forceUpdate()
        field.flow.handleSubmit(field)
      }
    }
    MC.handleEvent(field, 'click', null, e)
  }

  onOpenIconClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let item = this.getItemByIndex(e.currentTarget.closest('.mnc.item').dataset.i)
    if (item.enabled == false) {
      return
    }
    item.opened = !item.opened
    this.toggled = true
    if (item.opened) {
      this.recalcDirection = true
    }
    this.setState({'right': false})
    MC.putFieldParamValue(this.props.data.param, '@focusedKey', item.key)
    MC.handleEvent(this.props.data, 'change')
  }

  handleRootMouseEnter(e) {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      let item = this.getItemByIndex(e.currentTarget.dataset.i)
      if (Array.isArray(item.items) && item.items.length > 0) {
        item.opened = true
        this.toggled = true
        this.recalcDirection = true
        this.setState({'right': false})
      }
    }
  }

  handleRootMouseLeave(e) {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      let item = this.getItemByIndex(e.currentTarget.dataset.i)
      if (Array.isArray(item.items) && item.items.length > 0) {
        item.opened = false
        this.toggled = true
        this.forceUpdate()
      }
    }
  }

  buildSubmenu(item, rooti) {
    let submenu = ""
    let params = this.props.data.param
    let activeKey = MC.getFieldParamValue(params, '@activeKey')
    let behavior = MC.getFieldParamValue(params, '@behavior')
    if (MC.isNull(rooti) && !MC.isNull(activeKey)) {
      if (this.activeKey !== activeKey || !this.props.data.activeKeyWasRecalculated) {
        this.recalculateActiveKey(activeKey)
      }
    }
    let target = MC.getFieldParamValue(this.props.data.param, '@target')
    target = (['blank', 'parent', 'top'].indexOf(target) > -1) ? '_' + target : null
    if (Array.isArray(item['items']) && item['items'].length > 0) {
      if (item.opened || MC.isNull(rooti)) {
        let asDropdown = MC.getFieldParamBooleanValue(params, '@dropdownSubMenu')
        let vertical = MC.getFieldParamBooleanValue(params, '@vertical')
        let iconPlacement = MC.getFieldParamValue(params, '@iconPlacement') || 'left'
        let items = []
        for (let i=0; i<item['items'].length; i++) {
          let strI = !MC.isNull(rooti) ? rooti + '-' + i : i
          let propItem = item['items'][i]
          let siconLeft = null
          let siconRight = null
          if (typeof propItem.icon == 'string') {
            if (iconPlacement == 'right') {
              siconRight = <span className="icon-span-right"><i className={MC.buildIconClass(propItem.icon)}/></span>
            } else {
              siconLeft = <span className="icon-span-left"><i className={MC.buildIconClass(propItem.icon)}/></span>
            }
          }
          let submenu = this.buildSubmenu(propItem, strI)
          let active = propItem.key && activeKey && propItem.key == activeKey
          let cls = MC.classes('mnc', {'active': active, 'visible': submenu && (asDropdown || !vertical), 'opened': submenu && !asDropdown, 'dropdown': submenu && (asDropdown || !vertical), 'disabled': propItem.enabled == false}, propItem.cssclass, 'item curpointer')
          let tooltip = propItem.tooltip ? propItem.tooltip : null
          let href = null
          if (!MC.isNull(propItem.url) && propItem.url !== '' || propItem.url === '' && behavior == 'url') {
            href = propItem.url
          }
          let title = <React.Fragment>{siconLeft}{Value.castToScalar(Value.fromJson(propItem.title), 'string').value || '\u00A0'}{siconRight}</React.Fragment>
          if (href) {
            title = <a href={href} className={MC.classes({'active': active})} target={target}>{title}</a>
          }
          let openIco = null
          if (vertical && !asDropdown && propItem.openable !== false && Array.isArray(propItem['items']) && propItem['items'].length > 0) {
            openIco = <i className="caret right icon"/>
            if (propItem.opened) {
              openIco = <i className="caret down icon"/>
            }
            openIco = <span className="open-ico" onClick={this.onOpenIconClick}>{openIco}</span>
          }
          let itemWrap = <React.Fragment>{openIco}{title}</React.Fragment>
          if (submenu) {
            itemWrap = <div className="item-wrapper" data-tt-content={tooltip}>{itemWrap}</div>
            tooltip = null
          }
          items.push(
            <div className={cls} style={MC.styleObjectFromString(propItem.css)} onClick={this.onclick} data-i={strI} key={'item' + strI} data-tt-content={tooltip} onMouseEnter={this.handleRootMouseEnter} onMouseLeave={this.handleRootMouseLeave}>
              {itemWrap}{submenu}
            </div>)
        }
        let mcls = MC.classes('menu transition visible', {'right': this.state.right})
        let ref = null
        if (MC.isNull(rooti)) {
          ref = this.menuRef
          mcls = MC.classes('mnc menu', MC.getFieldParamBooleanValue(params, '@fitWidth') ? 'fluid' : 'compact', {'vertical': MC.getFieldParamBooleanValue(params, '@vertical')}, MC.getFieldParamValue(params, '@cssClass'))
        }
        submenu = <div className={mcls} style={MC.styleObjectFromString(MC.getFieldParamValue(params, '@css'))} ref={ref}>{items}</div>
      }
    }
    return submenu
  }

  render() {
    return this.buildSubmenu(this.props.data.param, null)
  }
}

export default Menu
