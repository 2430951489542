import React from "react"

import {MC} from './MC.js'

class Label extends React.Component {

  render() {
    const field = this.props.field
    let value = this.props.value
    let cssclass = MC.getFieldParamValue(field.param, '@cssClass')
    let css = MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))
    let HeaderType =  MC.getFieldParamValue(field.param, '@headerType')
    let escapeHtml = MC.getFieldParamValue(field.param, '@escapeHtml')    
    if (MC.isNull(HeaderType)) {
      if (escapeHtml) {
        return <span className={cssclass} style={css} id={field.rbsid} data-widget-i-name={field.id}>{MC.iconize(field, <span>{value}</span>)}</span>
      } else {
        return MC.iconize(field, <div className={cssclass} style={css} id={field.rbsid} dangerouslySetInnerHTML={{__html: MC.customHtml(value)}} data-widget-i-name={field.id}/>)
      }
    } else {
      let halign = MC.getFieldParamValue(field.param, '@horizontalAlignment')
      cssclass = MC.classes('mnc header flex-row flex-middle', cssclass, {'flex-center': 'center' == halign, 'flex-right': 'right' == halign})
      HeaderType = HeaderType.toLowerCase()
      if (escapeHtml) {
        return <HeaderType className={cssclass} style={css} id={field.rbsid} data-widget-i-name={field.id}>{MC.iconize(field, <span>{value}</span>)}</HeaderType>
      } else {
        return <HeaderType className={cssclass} style={css} id={field.rbsid} data-widget-i-name={field.id}>{MC.iconize(field, <div dangerouslySetInnerHTML={{__html: MC.customHtml(value)}}/>)}</HeaderType>
      }
    }
  }

}

export {Label}