import React from "react"

import {MC} from './MC.js'
import {MCHistory} from "./MCHistory.js"
import {ReactFlow} from "./ReactFlow.jsx"

class EmbeddedDialog extends React.Component {

  state = {flowName: null, start: false, ended: false, input: {}}

  componentDidMount() {
    window.addEventListener("message", this.onMessage, false)
    this.operateReload(true)
  }

  componentDidUpdate() {
     this.operateReload(false)
  }

  componentWillUnmount () {
    window.removeEventListener("message", this.onMessage, false)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.start || nextState.savedState || MC.getFieldParamBooleanValue(nextProps.data.param, '@reload') || nextState.ended) {
      return true
    }
    return false
  }

  operateReload(init) {
    let field = this.props.data
    if (MC.getFieldParamBooleanValue(field.param, '@reload')) {
      MC.putFieldParamValue(field.param, '@reload', false)
      this.startFlow()
    } else if (this.state.start) {
      this.setState({start: false, input: {}})
    } else if (init) {
      let savedState = field.flow.reactFlow().pickDestroyedChild(field.rbsid)
      if (savedState) {
        this.setState({savedState: savedState})
      } else {
        let form = MC.findRoot(field)
        // reactForm is set, it meeans that em. dialog was hidden when form was mount and now is made visible
        // (if it is first mount inside form mount, reactForm is nost set, beacuse form didMount is later than this didMount)
        if (form.reactForm && MC.getFieldParamBooleanValue(field.param, '@dataActionAutoLoad')) { 
          this.startFlow()
        }
      }
    } else if (this.state.savedState) {
      this.setState({savedState: null})
    }
  }

  onMessage = (e) => {
    let self = this
    if (e.data && MC.isPlainObject(e.data)) {
      if ('MNC-INTERNAL.READY-RUN' === e.data.name && self.props.data.flow.instanceId === e.data.instanceId) {
        // start flow in e. dialog if auotload is set to true
        if (MC.getFieldParamBooleanValue(self.props.data.param, '@dataActionAutoLoad')) {
          this.startFlow()
        }
      }
    }
  }

  startFlow = () => {
    let lStart = performance.now()
    let lStartDate = Date.now()
    let field = this.props.data
    let flow = field.flow
    let config = {actionLink: MC.getFieldParamValue(field.param, '@dataEvent'), formExecutionId: this.props.formExecutionId}
    if (!config.actionLink) {
      flow.endOperationException('SYS_InvalidModelExc', 'Can not execute flow inside embedded dialog. No @dataEvent is set!')
      return
    }
    let dRes = flow.callLogicAction(config, field, true)
    if (!MC.isNull(dRes)) {
      let flowInstanceId = MC.generateId()
      this.setState({flowName: dRes.flowName, start: true, ended: false, input: dRes.input, trace: dRes.trace, actionCode: dRes.actionCode, altMapping: dRes.altMapping, flowInstanceId: flowInstanceId, action: dRes.action})
      MCHistory.history(flow, dRes.action, 'EM-DIALOG CALL', {'Input': dRes.input, 'Trace': dRes.trace, target: {...dRes.action.interface, ...{id: dRes.flowName}}, executionId: flowInstanceId}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart, dialog: dRes})
    }
  }

  onEnd  = (output, message, options) => {
    let flow = this.props.data.flow
    let dActionCode = this.state.actionCode
    let altMapping = this.state.altMapping
    this.setState({flowName: null, input: null, start: false, actionCode: null, altMapping: null, ended: true})
    if (!MC.isNull(message)) {
      flow.endOperationException(output, message, this.state.input, null, null)
    } else {
      let submitOpts = null
      const dAction = this.state.action
      if (dAction.submitParent || ['submit', 'store', 'cancel'].indexOf(options.endAction.parentAction) >= 0) {
        submitOpts = {}
        submitOpts.triggeredByField = this.props.data
      }
      if (options.endAction.parentAction == 'none') {
        flow.reactFlow().forceUpdate() // clear loader
      }
      MC.putFieldParamValue(this.props.data.param, 'value', output)
      flow.endEmbeddedDialog(dActionCode, this.state.input, output, submitOpts, null, altMapping, options.endAction)
    }
  }

  render() {
    if (this.state.ended) {
      return ""
    }
    const flow = this.props.data.flow
    const loader = MC.getFieldParamValue(this.props.data.param, '@loader') || flow.reactFlow().state.loader || 'all'
    const debugInParam = MC.getFieldParamValue(this.props.data.param, '@debug')
    let debugConsoleInParam = MC.getFieldParamBooleanValue(this.props.data.param, '@debugConsole')
    let console = debugInParam ? true : false
    if (console && debugConsoleInParam === false) {
      console = false
    }
    return <ReactFlow configuration={flow.confPath} flowName={this.state.flowName} env={flow.context.data.env} input={this.state.input} start={this.state.start} autoScrollUp={false} onEndFunction={this.onEnd} 
             embedded={true} console={console} options={flow.reactFlow().props.options} parent={flow.reactFlow()} emdialogId={this.props.data.rbsid} debug={debugInParam || flow.wantedLogLevel} 
             loader={loader} savedState={this.state.savedState} mconf={flow.reactFlow().props.mconf} instanceId={this.state.flowInstanceId} baseUrl={flow.baseUrl} submitByParent={this.state.action?.submitByParent}/>
  }

}

export {EmbeddedDialog}